<template>
  <div class="my">
    <img src="@/assets/img/help_icon.svg" class="help" @click="help">
    <div class="content">
      <img src="@/assets/img/header_bg.png" class="header_bg">
      <img :src="vImgUrl ? vImgUrl : require('@/assets/img/header_pic.png')" class="header_pic">
      <h2 class="userName">{{ vUserInfo.name }}</h2>

      <van-tabs v-model="active">
        <van-tab title="我的预约">
          <div class="reserve" v-if="reserveStatus == 1">

            <!-- <div class="reserveDet" @click="toDetail">
              <img src="@/assets/img/reserveHeadPic.png" class="reserveHeadPic">
              <div class="reserveDes">
                <p v-if="status == 1">时间：{{ assessDate }} {{ assessTime }}</p>
                <p>预约状态：{{ status == 0 ? '审核中' : status == 1 ? '预约成功' : '预约失败' }}</p>
                <p v-if="status == 2">失败原因：{{ remark }}</p>
              </div>
            </div> -->
            <div class="reserveDet" v-for="(item,i) in schedules" :key="i" @click="toDetail(item.scheduleId,item.status,item.paymentStatus,item.productCode,item.sn)">
              <img src="@/assets/img/coursePic.png" class="reserveHeadPic">
              <div class="reserveDes">
                <p>{{item.status != 3 && item.assessDate ? item.assessDate + ' ' + item.assessTime : item.completedDate}}</p>
                <p v-if="item.status == 0 && item.paymentStatus == 1" class="status0">
                  待支付
                  <span>查看详情 ></span>
                </p>
                <p v-if="item.status == 0 && item.paymentStatus == 2" class="status0">
                  已支付 受理中...
                  <span>查看详情 ></span>
                </p>
                <p v-else-if="item.status == 1" class="status1">
                  预约成功！
                  <span>查看凭证 ></span>
                </p>
                <p v-else-if="item.status == 2" class="status2">
                  申请失败
                  <span>{{item.remark}}</span>
                </p>
                <p v-else-if="item.status == 3" class="status1">
                  已完成
                  <span>查看记录 ></span>
                </p>
                <p v-else-if="item.status == 4" class="status2">
                  已取消
                </p>
              </div>
            </div>
          </div>
          <div class="reserve" v-else>
            <img src="@/assets/img/noData_bg.svg" class="noData">
            <p class="reserveNone">当前暂无预约</p>
          </div>
        </van-tab>
        <van-tab title="测评报告">
          <div class="report" v-if="reportStatus == 1">
            <div class="reserveDet" v-for="(item,i) in reportList" :key="i" @click="toReportDetail(item)">
              <img src="@/assets/img/reportHeadPic.png" class="reserveHeadPic">
              <div class="reserveDes">
                <p>{{vUserInfo.name}}的测评报告 <{{item.reportType == 0?"膝关节":"脊柱测量"}}></p>
                <p class="date">{{item.creatDte}}</p>
                <p class="link">点击查看 ></p>
              </div>
            </div>
          </div>

          <div class="reserve" v-else>
            <img src="@/assets/img/noData_bg.svg" class="noData">
            <p class="reserveNone">当前暂无报告</p>
          </div>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>
<script>
// https://blog.csdn.net/m0_46219714/article/details/121244051
import { scheduleStatus, authWechat, assessReport } from "@/api/getData"
export default {
  name: "My",
  // setup() {
  //   const active = $route.query.active;
  //   return { active };
  // },
  data() {
    return {
      assessDate: '',
      assessTime: '',
      status: '',
      remark: '',
      reserveStatus: '',
      schedules: [],
      reportStatus: '',
      reportList: [],
      active: Number(this.$route.query.active)
    }
  },
  created() {

  },
  mounted() {
    // this.active = this.$route.query.active
    // console.log(this.active)
    if (!this.vToken) {
      this.toweichat()
    } else {
      this.getStatus()
      this.getReportList()
    }
  },
  methods: {
    getReportList() {
      assessReport().then(res => {
        if (Object.keys(res.data).length == 0) {
          this.reportStatus = 0
        } else {
          this.reportStatus = 1
          this.reportList = res.data
        }
      })
    },
    toReportDetail(item) {
      if(item.reportType == 0){//膝关节
        this.$router.push({
          path: "/reportDetail",
          query: {
            reportId: item.reportId
          }
        })
      }else if(item.reportType == 1){//脊柱测量
        this.$router.push({
          path: "/spinescale-report",
          query: {
            reportId: item.reportId
          }
        })
      }
    },
    toDetail(scheduleId, status, paymentStatus,productCode,sn) {
      // to="/card" 
      if (status == 0 && paymentStatus == 1) {
        this.$router.push({
          path: "/wechatPay",
          query: {
            productCode: productCode,
            sn: sn,
            scheduleId: scheduleId
          }
        })
      } else if (status == 1) {
        this.$router.push({
          path: "/book", 
          query: {
            scheduleId: scheduleId
          }
        })
      } else if (status == 2 || status == 4) {

      } else {
        this.$router.push({
          path: "/card",
          query: {
            scheduleId: scheduleId
          }
        })
      }
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
    toweichat() {
      //判断是否是微信浏览器
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      {
        //判断是否是微信浏览器
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          let code = this.GetParam(window.location.href, "code");
          if (code) {
            //这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
            authWechat(code).then(res => {
              var userInfo = {}
              userInfo.name = res.data.name
              userInfo.mobile = res.data.moblie
              userInfo.birthday = res.data.birthday
              userInfo.gender = res.data.gender

              userInfo.wechatId =  res.data.wechatId
              userInfo.unionId =  res.data.unionId
              userInfo.wechatImgUrl =  res.data.wechatImgUrl
              userInfo.wechatNickname = res.data.wechatNickname
              // userInfo.assessDate = res.data.assessDate
              // userInfo.assessTime = res.data.assessTime
              // userInfo.wechatImgUrl = res.data.wechatImgUrl
              this.$store.commit('SET_vToken', res.data.token)
              this.$store.commit('SET_vUserInfo', userInfo)
              this.$store.commit('SET_vImgUrl', res.data.wechatImgUrl)
              this.getStatus()
              this.getReportList()
            })
          } else {
            //获取当前页面的地址
            let local = `${process.env.VUE_APP_CLIENT_URL}my`;//'http://wctest.ng.noitom.com.cn/#/my';
            //调接微信官方生成授权登录的url appid必须填写
            //wx51177af5a0aaad8d
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + `${process.env.VUE_APP_WECHAR_APPID}` + "&redirect_uri="
              + encodeURIComponent(local)
              + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
            //这一步是跳转这上方定义的url
            window.location.href = url;
          }
        } else {
          // this.$toast.fail({
          //   message: '请使用微信浏览器',
          //   forbidClick: true,
          //   overlay: true,
          //   duration: 0
          // })
        }
      }
    },
    help() {
      this.$dialog.alert({
        title: '帮助热线',
        message: '<p>感谢您的参与，我们致力为您提供专业服务</p><p>有任何问题请联系：</p><a href="tel:18657980270" class="blue">陈老师 18657980270</a><p class="hint">点击任意处关闭</p>',
        showConfirmButton: false,
        closeOnClickOverlay: true
      });
    },

    getStatus() {
      //Object.keys(res.data.schedules).length === 0
      scheduleStatus().then(res => {
        if (!res.data) {
          this.reserveStatus = 0
        } else {
          this.reserveStatus = 1
          this.schedules = res.data.schedules

          var userInfo = this.vUserInfo
          if(userInfo == undefined || userInfo == null){
            userInfo = {}
          }
          userInfo.name = res.data.name
          userInfo.mobile = res.data.mobile
          userInfo.birthday = res.data.birthday
          userInfo.gender = res.data.gender
          // userInfo.assessDate = res.data[0].assessDate
          // userInfo.assessTime = res.data[0].assessTime
          this.$store.commit('SET_vUserInfo', userInfo)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  position: relative;
  padding-top: 1.18rem;
  background-color: #24bbd3;

  .help {
    position: absolute;
    right: 0.16rem;
    top: 0.16rem;
    width: 0.31rem;
  }

  .content {
    border-radius: 8px 8px 0px 0px;
    background: #ffffff;
    position: relative;

    .header_bg {
      display: block;
      width: 1.2rem;
      margin: 0 auto;
      position: absolute;
      top: -0.6rem;
      left: 34%;
    }

    .header_pic {
      display: block;
      width: 1.08rem;
      margin: 0 auto;
      position: absolute;
      top: -0.54rem;
      left: 35.5%;
      border-radius: 50%;
      overflow: hidden;
    }

    .userName {
      text-align: center;
      padding-top: 0.7rem;
      font-size: 0.2rem;
      font-weight: 500;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding-bottom: 0.28rem;
    }

    .reserve,
    .report {
      padding: 0 0.16rem 0.3rem;

      .noData {
        display: block;
        width: 100%;
        margin-top: 0.32rem;
      }

      .reserveNone {
        text-align: center;
        font-size: 0.1rem;
        color: rgba(0, 0, 0, 0.6);
        position: relative;
        top: -0.26rem;
      }

      h3 {
        font-size: 0.2rem;
        margin: 0.3rem 0 0.2rem;
        font-weight: 500;
      }

      .reserveDet {
        padding: 0.22rem 0.14rem;
        display: flex;
        box-shadow: 3px 4px 5px 0px rgba(255, 255, 255, 0.3),
          0px 3px 5px 3px rgba(50, 111, 121, 0.05),
          0px 0px 12px 0px rgba(112, 214, 231, 0.08);
        border-radius: 0.08rem;
        margin-top: 0.18rem;

        .reserveHeadPic {
          display: block;
          width: 0.9rem;
          height: 0.9rem;
          margin-right: 0.2rem;
        }

        .reserveDes {
          flex: 1;
          justify-content: center;

          p {
            font-size: 0.14rem;
            line-height: 0.24rem;
            margin-top: 0.08rem;
            font-weight: 500;
            span {
              display: block;
              margin-top: 0.08rem;
              font-size: 0.14rem;
              line-height: 0.24rem;
              color: #24bbd3;
            }
          }
          p:first-child {
            margin-top: 0;
            line-height: 0.24rem;
          }
          .status0 {
            color: #ff8f1f;
          }
          .status1 {
            color: #00b578;
          }
          .status2 {
            color: rgba(0, 0, 0, 0.3);
          }
          .date {
            font-size: 0.14rem;
            color: rgba(0, 0, 0, 0.5);
            margin-top: 0.04rem;
            line-height: 0.24rem;
          }
          .link {
            color: #24bbd3;
            font-size: 0.14rem;
            margin-top: 0.24rem;
            line-height: 1;
          }
        }
      }
    }
  }

  :deep(.van-tabs__wrap) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    height: 0.44rem;

    .van-tab {
      font-size: 0.18rem;
      color: #969799;
    }
    .van-tab__text--ellipsis {
      overflow: unset;
    }

    .van-tab--active {
      color: #323233;
    }

    .van-tabs__line {
      background: #24bbd3;
    }
  }
}
</style>